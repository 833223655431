.breadcrumb small {
  width: 140px;
  overflow: hidden;
  font-family: var(--font-mono);
  position: relative;
}

.breadcrumb small:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  background: linear-gradient(270deg, black, transparent);
  width: 32px;
}

.breadcrumb .roleName {
  line-height: 18.5px;
}
@media (max-width: 960px) {
  .container {
    flex-wrap: wrap;
  }
}

.members {
  width: 350px;
  min-width: 300px;
  flex-grow: 1;
}
.permissions {
  flex-grow: 9999;
}

.header {
  margin-bottom: 24px;
}

.header h1 {
  font-size: 24px;
  line-height: normal;
  text-align: left;
  margin-bottom: 10px;
}

.header h1.rawRoleKey {
  opacity: 0.7;
  font-family: var(--font-mono);
}

.header label {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.7);
  background-color: var(--bg-color);
  width: fit-content;
  padding: 0px 5px;
  margin-bottom: 5px;
  margin-left: -2px;
  display: block;
}

.roleKey p {
  opacity: 0.7;
  font-family: var(--font-mono);
  font-size: 12px;
}
