.button {
  font-family: var(--font-body);
  font-size: 16px;
  display: block;
  width: 100%;
  box-sizing: border-box;
  background: var(--bg-color);
  border: 1px solid var(--bg-color);
  text-decoration: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  color: white;
  transition: all 0.1s ease;
}

.button:hover:enabled {
  cursor: pointer;
  border-color: rgba(217, 212, 173, 0.8);
}
a.button:hover {
  color: white;
  background-color: var(--bg-color-hover);
}

.button:disabled {
  opacity: 0.6;
}

.button.primary {
  background: linear-gradient(
    90deg,
    rgba(43, 34, 101, 0.08) -26.61%,
    #223265 49.61%,
    rgba(43, 34, 101, 0.09) 119.72%
  );
}
