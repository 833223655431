.field {
  width: 100%;
}

.labelContainer {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-2);
  align-items: flex-start;
  background-color: var(--bg-color);
  padding: var(--spacing-1) var(--spacing-2) var(--spacing-2) var(--spacing-2);
}

.fieldLabel {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: rgba(255, 255, 255, 0.7);

  width: fit-content;
  display: block;
}

.fieldBody {
  width: 100%;
}

.disabled {
  opacity: 0.8;
  pointer-events: none;
}
